import React from "react";
import { PingPonger } from "../components/PingPonger";

export const AccountMerge = () => {
  return (
    <>
      <h2>Merge</h2>
      <PingPonger />
    </>
  );
};
