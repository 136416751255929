import React from "react";
import { useState } from "react";
import Button from "@design-system/component-library/src/components/Button";

export const PingPonger = () => {
  const [response, setResponse] = useState("nothing yet...");

  async function pong() {
    setResponse("pinging...");
    const response = await fetch("/api/ping");
    const responseText = await response.text();
    setResponse(responseText);
  }

  return (
    <>
      <h2>Server says: {response}</h2>
      <Button onClick={() => pong()}>Ping it</Button>
    </>
  );
};
