@include themify($themes) {
  .loadingspinner {
    display: inline-block;
    position: relative;
    width: $ds-baseline * 6;
    height: $ds-baseline * 6;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: $ds-baseline * 6;
      height: $ds-baseline * 6;
      margin: 0;
      border: ($ds-baseline * 0.5) solid themed('presentationActionColor');
      border-radius: 50%;
      animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: themed('presentationActionColor') transparent transparent transparent;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }
      &:nth-child(2) {
        animation-delay: -0.3s;
      }
      &:nth-child(3) {
        animation-delay: -0.15s;
      }
      @keyframes loader-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    &--size {
      &-xs {
        width: $ds-baseline * 2;
        height: $ds-baseline * 2;

        div {
          border-width: $ds-baseline * 0.25;
          width: $ds-baseline * 2;
          height: $ds-baseline * 2;
        }
      }

      &-s {
        width: $ds-baseline * 3;
        height: $ds-baseline * 3;

        div {
          border-width: $ds-baseline * 0.25;
          width: $ds-baseline * 3;
          height: $ds-baseline * 3;
        }
      }

      &-m {
        width: $ds-baseline * 4;
        height: $ds-baseline * 4;

        div {
          border-width: $ds-baseline * 0.25;
          width: $ds-baseline * 4;
          height: $ds-baseline * 4;
        }
      }

      &-l {
        width: $ds-baseline * 5;
        height: $ds-baseline * 5;

        div {
          border-width: $ds-baseline * 0.5;
          width: $ds-baseline * 5;
          height: $ds-baseline * 5;
        }
      }

      &-xl {
        width: $ds-baseline * 6;
        height: $ds-baseline * 6;

        div {
          border-width: $ds-baseline * 0.5;
          width: $ds-baseline * 6;
          height: $ds-baseline * 6;
        }
      }
    }
  }
}
