"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BREAKPOINT_XL_MAX_WIDTH = exports.BREAKPOINT_L_MAX_WIDTH = exports.BREAKPOINT_M_MAX_WIDTH = exports.BREAKPOINT_S_MAX_WIDTH = exports.BREAKPOINT_XS_MAX_WIDTH = exports.BREAKPOINT_XL = exports.BREAKPOINT_L = exports.BREAKPOINT_M = exports.BREAKPOINT_S = exports.BREAKPOINT_XS = exports.FOCUSABLE_SELECTOR = void 0;
exports.classNames = classNames;
exports.default = {};
/**
 * Simple implementation of classNames, doesn't support string+boolean
 *   key-value-pairs.
 * @param {(false|null|string|undefined)[]} classes Array of class names or something null-ish
 * @returns {string} Found class names joined with whitespace.
 */
function classNames(classes) {
    return classes.filter(Boolean).join(' ');
}
exports.FOCUSABLE_SELECTOR = "a[href]:not([tabindex^=\"-\"]),area[href]:not([tabindex^=\"-\"]),audio[controls]:not([tabindex^=\"-\"]),button:not([disabled]):not([tabindex^=\"-\"]),iframe:not([tabindex^=\"-\"]),input:not([type=\"hidden\"]):not([type=\"radio\"]):not([disabled]):not([tabindex^=\"-\"]),input[type=\"radio\"]:not([disabled]):not([tabindex^=\"-\"]),select:not([disabled]):not([tabindex^=\"-\"]),textarea:not([disabled]):not([tabindex^=\"-\"]),video[controls]:not([tabindex^=\"-\"]),[contenteditable]:not([tabindex^=\"-\"]),[tabindex]:not([tabindex^=\"-\"])";
// Breakpoints from /src/globals/scss/_variables.scss
exports.BREAKPOINT_XS = 0;
exports.BREAKPOINT_S = 481;
exports.BREAKPOINT_M = 641;
exports.BREAKPOINT_L = 961;
exports.BREAKPOINT_XL = 1400;
exports.BREAKPOINT_XS_MAX_WIDTH = exports.BREAKPOINT_S - 1;
exports.BREAKPOINT_S_MAX_WIDTH = exports.BREAKPOINT_M - 1;
exports.BREAKPOINT_M_MAX_WIDTH = exports.BREAKPOINT_L - 1;
exports.BREAKPOINT_L_MAX_WIDTH = exports.BREAKPOINT_XL - 1;
exports.BREAKPOINT_XL_MAX_WIDTH = exports.BREAKPOINT_XL;
