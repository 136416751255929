import { createRoot } from "react-dom/client";
import { App } from "./App";
import React from "react";

(() => {
  const appElement = document.getElementById("app");
  if (!appElement) {
    console.error("element with id 'app' is missing");
    return;
  }

  const root = createRoot(appElement);
  root.render(<App />);
})();
