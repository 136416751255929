@include themify($themes) {
  $ds-icon__size--s: themed('iconSizeS'); // $ds-baseline * 2;
  $ds-icon__size--m: themed('iconSizeM'); // $ds-baseline * 3;
  $ds-icon__size--l: themed('iconSizeL'); // $ds-baseline * 4;
  $ds-icon__size--xl: themed('iconSizeXL'); // $ds-baseline * 6;
  $ds-icon__size--default: $ds-icon__size--m;

  .icon {
    display: inline-block;

    svg {
      fill: themed('textColor');
    }

    &--color {
      &-primary {
        svg {
          fill: themed('textColorSecondary');
        }
      }
      &-invert {
        svg {
          fill: themed('textColorInverse');
        }
      }
      @each $name, $color in $ds-colors {
        &-#{$name} {
          svg {
            fill: #{$color} !important;
          }
        }
      }
    }

    &--size {

      &-s {
        width: $ds-icon__size--s;
        height: $ds-icon__size--s;
      }
      &-m {
        width: $ds-icon__size--m;
        height: $ds-icon__size--m;
      }
      &-l {
        width: $ds-icon__size--l;
        height: $ds-icon__size--l;
      }
      &-xl {
        width: $ds-icon__size--xl;
        height: $ds-icon__size--xl;
      }

      &-fluid {
        width: 1em;
        height: 1em;
      }
    }
  }
}

.reset {
  .icon {
    box-sizing: content-box;
    -webkit-box-sizing: content-box;
  }
}