import "./globals.scss";
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AccountRegistration } from "./routes/AccountRegistration";
import { AccountMerge } from "./routes/AccountMerge";
import { Layout } from "./components/Layout";
import { ErrorPage } from "./routes/ErrorPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <AccountRegistration />,
      },
      {
        path: "registration",
        element: <AccountRegistration />,
      },
      {
        path: "merge",
        element: <AccountMerge />,
      },
    ],
  },
]);

export const App = () => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};
