"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePrevious = exports.useMediaQuery = exports.useIdWithFallback = void 0;
var react_1 = require("react");
exports.default = {};
/**
 * @param {string} prefix - Prefix for the generated ID
 * @param {null|string} [id] - ID provided in component props if available
 * @returns {string} Either the given id, or a memoized nextId
 */
var useIdWithFallback = function (prefix, id) {
    var generatedId = (0, react_1.useId)();
    return id !== null && id !== void 0 ? id : "".concat(prefix, "-").concat(generatedId.replace(/\:/g, ''));
};
exports.useIdWithFallback = useIdWithFallback;
/**
 * @param {string} query See https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
 * @return {boolean|null}
 */
var useMediaQuery = function (query) {
    // It's important that we return null (or false) on the first run,
    // so that this returns the same result on SSR and browser.
    var _a = __read((0, react_1.useState)(null), 2), matches = _a[0], setMatches = _a[1];
    (0, react_1.useEffect)(function () {
        var _a;
        // Make sure we have window.matchMedia: this might be run on SSR or Jest
        // or something else that might be missing window or matchMedia.
        // If we can't generate the matcher, we never update the value from null.
        var matchMedia = (_a = window === null || window === void 0 ? void 0 : window.matchMedia) === null || _a === void 0 ? void 0 : _a.call(window, query);
        if (matchMedia) {
            var handleChange_1 = function () { return setMatches(matchMedia.matches); };
            handleChange_1();
            // TODO remove addListener & removeListener once Safari <= 13 usage is low enough.
            // At the time of writing, in yrityksille.elisa.fi it's about 0.43%.
            if (matchMedia.addEventListener) {
                matchMedia.addEventListener("change", handleChange_1);
            }
            else {
                matchMedia.addListener(handleChange_1);
            }
            return function () {
                if (matchMedia.removeEventListener) {
                    matchMedia.removeEventListener("change", handleChange_1);
                }
                else {
                    matchMedia.removeListener(handleChange_1);
                }
            };
        }
        return function () {
            /* NOOP, there's nothing to clean up. */
        };
    }, [query]);
    return matches;
};
exports.useMediaQuery = useMediaQuery;
/**
 * Mostly used to check if the value has changed.
 * @param value New value
 * @returns Old value
 */
var usePrevious = function (value) {
    var ref = (0, react_1.useRef)();
    (0, react_1.useEffect)(function () {
        ref.current = value;
    }, [value]);
    return ref.current;
};
exports.usePrevious = usePrevious;
