@include themify($themes) {
  $ds-button__font-size--s: 1rem * 0.6875;
  $ds-button__line-height--s: 1rem;
  $ds-button__font-size--m: 1rem * 0.8125;
  $ds-button__line-height-m: 1rem;
  $ds-button__font-size--l: 1rem * 0.875;
  $ds-button__line-height--l: 1rem * 1.25;

  .button {
    font-family: themed('headerFontFamily');
    border-style: solid;
    border-width: 1px;
    border-radius: $ds-form-element-radius;
    font-size: 1rem;
    font-weight: themed('actionButtonFontWeight');
    text-transform: uppercase;
    text-align: center;
    outline: none;
    box-sizing: border-box;
    letter-spacing: 0.5px;
    vertical-align: middle;
    position: relative;

    &__content {
      display: flex;
      align-items: center;
      justify-content: center;

      &--starts_with_icon {
        font-family: themed('headerFontFamily');

        .icon:first-of-type {
          margin-right: $ds-baseline;
        }
      }

      &--ends_with_icon {
        font-family: themed('headerFontFamily');

        .icon:last-of-type {
          margin-left: $ds-baseline;
        }
      }

      &--icon_in_top {
        flex-direction: column;

        .icon:first-of-type {
          margin-bottom: $ds-baseline * 0.5;
        }
      }

      &--icon_in_bottom {
        flex-direction: column;

        .icon:last-of-type {
          margin-top: $ds-baseline * 0.5;
        }
      }
    }

    &--size {
      &-s {
        padding: $ds-baseline * 0.75 $ds-baseline * 1.75;
        font-size: $ds-button__font-size--s;
        line-height: $ds-button__line-height--s;
        min-height: $ds-baseline * 4;
        min-width: $ds-baseline * 4;

        .icon {
          height: $ds-baseline * 2;
          width: $ds-baseline * 2;
          min-height: $ds-baseline * 2;
          min-width: $ds-baseline * 2;
        }
      }

      &-m {
        padding: $ds-baseline * 1.25 $ds-baseline * 2.75;
        font-size: $ds-button__font-size--m;
        line-height: $ds-button__line-height-m;
        min-height: $ds-baseline * 5;
        min-width: $ds-baseline * 5;

        .icon {
          height: $ds-baseline * 2;
          width: $ds-baseline * 2;
          min-height: $ds-baseline * 2;
          min-width: $ds-baseline * 2;
        }
      }

      &-l {
        padding: $ds-baseline * 1.25 $ds-baseline * 3.75;
        font-size: $ds-button__font-size--l;
        line-height: $ds-button__line-height--l;
        min-height: $ds-baseline * 6;
        min-width: $ds-baseline * 6;

        .icon {
          height: $ds-baseline * 3;
          width: $ds-baseline * 3;
          min-height: $ds-baseline * 3;
          min-width: $ds-baseline * 3;
        }
      }
    }



    &--icon-only {
      &.button--size {
        &-s {
          padding: $ds-baseline * 0.75;
        }

        &-m {
          padding: $ds-baseline * 1.25;
        }

        &-l {
          padding: $ds-baseline * 1.25;
        }
      }
    }

    &--block:not([hidden]) {
      display: block;
      width: 100%;
    }

    &--loading {
      pointer-events: none;
      position: relative;

      .button__content {
        visibility: hidden;
      }

      .loadingspinner {
        position: absolute;
        left: 50%;
        z-index: 5;
      }

      &.button--size-s {
        padding-top: $ds-baseline * 0.5;
        padding-bottom: $ds-baseline * 0.5;

        .loadingspinner {
          top: $ds-baseline * 0.75;
          margin-left: -$ds-baseline;
        }
      }

      &.button--size-m {
        padding-top: $ds-baseline * 0.5;
        padding-bottom: $ds-baseline * 0.5;

        .loadingspinner {
          top: $ds-baseline * 0.75;
          margin-left: -$ds-baseline * 1.5;
        }
      }

      &.button--size-l {
        padding-top: $ds-baseline * 0.5;
        padding-bottom: $ds-baseline * 0.5;

        .loadingspinner {
          top: $ds-baseline * 0.5;
          margin-left: -$ds-baseline * 2;
        }
      }
    }

    &:hover {
      cursor: pointer;
    }

    &:focus-visible {
      &::after {
          content: "";
          width: calc( 100% + $ds-baseline ) ;
          height: calc( 100% + $ds-baseline );
          background: transparent;
          position: absolute;
          display: block;
          top: $ds-baseline * -0.5;
          left: $ds-baseline * -0.5;
          box-shadow: $ds-focus-glow--outer;
          border-radius: $ds-form-element-radius;
        }
    }

    .icon {
      vertical-align: middle;
    }

    &__icon-first {
      padding: 0 $ds-baseline 0 0;
    }

    &__icon-last {
      padding: 0 0 0 $ds-baseline;
    }
  }

  .button {
    &--color {
      &-disabled {
        color: rgba(themed('actionTextDisabled'), 0.5);
        background-color: themed('actionBackgroundDisabled');
        border-color: themed('actionBackgroundDisabled');
        pointer-events: none;

        &:hover {
          cursor: default;
        }

        .icon {
          svg {
            fill: rgba(themed('actionTextDisabled'), 0.5);
          }
        }

        .loadingspinner {
          div {
            border-color: themed('actionTextDisabled') transparent transparent transparent;
          }
        }
      }

      &-primary {
        color: themed('actionText');
        background-color: themed('buttonBackgroundDefault');
        border-color: themed('buttonBackgroundDefault');

        &:hover {
          background-color: darken(themed('buttonBackgroundDefault'), 5%);
          border-color: darken(themed('buttonBackgroundDefault'), 5%);
        }

        &:active {
          background-color: lighten(themed('buttonBackgroundDefault'), 5%);
          border-color: lighten(themed('buttonBackgroundDefault'), 5%);
        }

        .icon {
          svg {
            fill: themed('actionText');
          }
        }

        .loadingspinner {
          div {
            border-color: themed('actionText') transparent transparent transparent;
          }
        }
      }

      &-darkblue {
        color: themed('actionText');
        background-color: themed('actionBackgroundPrimary');
        border-color: themed('actionBackgroundPrimary');

        &:hover {
          background-color: darken(themed('actionBackgroundPrimary'), 5%);
          border-color: darken(themed('actionBackgroundPrimary'), 5%);
        }

        &:active {
          background-color: lighten(themed('actionBackgroundPrimary'), 5%);
          border-color: lighten(themed('actionBackgroundPrimary'), 5%);
        }

        .icon {
          svg {
            fill: themed('actionText');
          }
        }

        .loadingspinner {
          div {
            border-color: themed('actionText') transparent transparent transparent;
          }
        }
      }

      &-linkblue {
        color: themed('actionText');
        background-color: themed('actionBackgroundLinkblue');
        border-color: themed('actionBackgroundLinkblue');

        &:hover {
          background-color: darken(themed('actionBackgroundLinkblue'), 5%);
          border-color: darken(themed('actionBackgroundLinkblue'), 5%);
        }

        &:active {
          background-color: lighten(themed('actionBackgroundLinkblue'), 5%);
          border-color: lighten(themed('actionBackgroundLinkblue'), 5%);
        }

        .icon {
          svg {
            fill: themed('actionText');
          }
        }

        .loadingspinner {
          div {
            border-color: themed('actionText') transparent transparent transparent;
          }
        }
      }

      &-link {
        color: themed('actionBackgroundLinkblue');
        background-color: $ds-color__transparent;
        border-color: $ds-color__transparent;

        &:hover {
          background-color: darken(themed('actionBackgroundNeutral'), 5%);
          border-color: darken(themed('actionBackgroundNeutral'), 5%);
        }

        &:active {
          background-color: lighten(themed('actionBackgroundNeutral'), 5%);
          border-color: lighten(themed('actionBackgroundNeutral'), 5%);
        }

        .icon {
          svg {
            fill: themed('actionBackgroundLinkblue');
          }
        }

        .loadingspinner {
          div {
            border-color: themed('actionBackgroundLinkblue') transparent transparent transparent;
          }
        }
      }

      &-light {
        color: themed('ButtonLightBorder');
        background-color: $ds-color__transparent;
        border-color: themed('ButtonLightBorder');

        &:hover {
          background-color: rgba(themed('actionBackgroundLight'), 0.05);
        }

        &:active {
          background-color: rgba(themed('actionBackgroundLight'), 0.05);
        }

        .icon {
          svg {
            fill: themed('actionTextInverse');
          }
        }

        .loadingspinner {
          div {
            border-color: themed('actionTextInverse') transparent transparent transparent;
          }
        }
      }

      &-light-inverse {
        color: themed('actionBackgroundInverse');
        background-color: $ds-color__transparent;
        border-color: themed('actionBackgroundInverse');

        &:hover {
          background-color: rgba(themed('actionBackgroundInverse'), 0.05);
        }

        &:active {
          background-color: rgba(themed('actionBackgroundLight'), 0.15);
        }

        .icon {
          svg {
            fill: themed('actionBackgroundInverse');
          }
        }

        .loadingspinner {
          div {
            border-color: themed('actionBackgroundInverse') transparent transparent transparent;
          }
        }
      }

      &-white {
        color: themed('actionBackground');
        background-color: themed('actionBackgroundNeutral');
        border-color: themed('actionBackgroundNeutral');

        &:hover {
          background-color: darken(themed('actionBackgroundNeutral'), 5%);
          border-color: darken(themed('actionBackgroundNeutral'), 5%);
        }

        &:active {
          background-color: lighten(themed('actionBackgroundNeutral'), 5%);
          border-color: lighten(themed('actionBackgroundNeutral'), 5%);
        }

        .icon {
          svg {
            fill: themed('actionBackground');
          }
        }

        .loadingspinner {
          div {
            border-color: themed('actionBackground') transparent transparent transparent;
          }
        }
      }

      &-ok,
      &-success {
        color: themed('actionText');
        background-color: $ds-color__success;
        border-color: $ds-color__success;

        &:hover {
          background-color: darken($ds-color__success, 5%);
          border-color: darken($ds-color__success, 5%);
        }

        &:active {
          background-color: lighten($ds-color__success, 1%);
          border-color: lighten($ds-color__success, 1%);
        }

        .icon {
          svg {
            fill: themed('actionText');
          }
        }

        .loadingspinner {
          div {
            border-color: themed('actionText') transparent transparent transparent;
          }
        }
      }

      &-warning {
        color: themed('actionText');
        background-color: $ds-color__warning;
        border-color: $ds-color__warning;

        &:hover {
          background-color: darken($ds-color__warning, 5%);
          border-color: darken($ds-color__warning, 5%);
        }

        &:active {
          background-color: lighten($ds-color__warning, 1%);
          border-color: lighten($ds-color__warning, 1%);
        }

        .icon {
          svg {
            fill: themed('actionText');
          }
        }

        .loadingspinner {
          div {
            border-color: themed('actionText') transparent transparent transparent;
          }
        }
      }

      &-danger,
      &-error {
        color: themed('actionText');
        background-color: $ds-color__danger;
        border-color: $ds-color__danger;

        &:hover {
          background-color: darken($ds-color__danger, 5%);
          border-color: darken($ds-color__danger, 5%);
        }

        &:active {
          background-color: lighten($ds-color__danger, 5%);
          border-color: lighten($ds-color__danger, 5%);
        }

        .icon {
          svg {
            fill: themed('actionText');
          }
        }

        .loadingspinner {
          div {
            border-color: themed('actionText') transparent transparent transparent;
          }
        }
      }
    }
  }
}

$ds-button__reset-font-size--s: $ds-baseline * 1.375;
$ds-button__reset-line-height--s: $ds-baseline * 1.5;
$ds-button__reset-font-size--m: $ds-baseline * 1.625;
$ds-button__reset-line-height-m: $ds-baseline * 2;
$ds-button__reset-font-size--l: $ds-baseline * 1.75;
$ds-button__reset-line-height--l: $ds-baseline * 3;

.reset {
  .button {
    &--size {
      &-s {
        font-size: $ds-button__reset-font-size--s;
        line-height: $ds-button__reset-line-height--s;
      }

      &-m {
        font-size: $ds-button__reset-font-size--m;
        line-height: $ds-button__reset-line-height-m;
      }

      &-l {
        font-size: $ds-button__reset-font-size--l;
        line-height: $ds-button__reset-line-height--l;
      }
    }
    &__content {
      box-sizing: content-box;
    }
  }
}
