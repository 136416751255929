import React from "react";

export const ErrorPage = () => {
  return (
    <>
      <h2>Page not found</h2>
      <p>The page you are looking for does not exist!</p>
    </>
  );
};
